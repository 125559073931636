import { writable } from 'svelte/store';

export interface AlertInfo {
  level: 'success' | 'failure' | 'info';
  message: string;
  /** Should we have an x to dismiss it? */
  dismissable?: boolean;
  /** In seconds */
  delay?: number;
  /** Retry button callback */
  retryCallback?: () => void;
  /** Cancel button callback */
  cancelCallback?: () => void;
}

let uniqueId = 0;
export interface AlertInfoWithUUID extends AlertInfo {
  id: number | string;
}

export const store = writable<AlertInfoWithUUID[]>([]);

/**
 * Add an alert, returns a unique id to allow updating or
 * replacing of the alert.
 */
export function addAlert(newAlert: AlertInfo): number {
  const id = ++uniqueId;
  return addAlertWithId(newAlert, id);
}

function addAlertWithId<T extends number | string>(newAlert: AlertInfo, id: T) {
  const newAlertWithId = {
    ...newAlert,
    id,
  };
  store.update((value) => [...value, newAlertWithId]);
  if (newAlert.delay && typeof window !== 'undefined') {
    window.setTimeout(() => removeAlert(newAlertWithId.id), newAlert.delay * 1000);
  }
  return id;
}

export function updateAlert(id: number | string, alertInfo: AlertInfo) {
  let alertFound = false;
  store.update((alerts) => {
    const existingAlert = alerts.find((a) => a.id === id);
    alertFound = !!existingAlert;
    if (existingAlert) {
      return alerts.map((a) => {
        if (a.id !== id) return a;
        return {
          ...a,
          ...alertInfo,
        };
      });
    }
    return alerts;
  });
  if (!alertFound) {
    addAlertWithId(alertInfo, id);
  }
}

export function removeAlert(id: number | string): void {
  store.update((value) => value.filter((a) => a.id !== id));
}

export function addAlertFromError(err: unknown, defaultMessage: string): void {
  let message: string;
  if (typeof err === 'string') {
    message = err;
  } else if (typeof err === 'object' && err && 'message' in err) {
    message = (err as { message: string }).message || defaultMessage;
  } else {
    message = defaultMessage;
  }
  addAlert({
    level: 'failure',
    message,
    dismissable: true,
  });
}
